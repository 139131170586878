import * as React from "react";
import { Link } from "../atoms/Link";
import screenshot from "../../images/screenshot-gestion-demandes-groupe.png";
import { LANG_FR_FR } from "../../constants";

export const BannerImage = ({ locale = LANG_FR_FR }) => {
  if (locale !== LANG_FR_FR) return null;
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-action-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  La plateforme de téléexpertise qui facilite et valorise la
                  collaboration médicale
                </span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-action-200">
                Omnidoc vous permet d'échanger simplement des avis médicaux,
                avec vos correspondants ou dans le cadre d'organisations
                territoriales.
              </p>
              <Link
                to="/"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-action-600 hover:bg-action-50"
              >
                En savoir plus
              </Link>
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-10 lg:translate-y-20"
              src={screenshot}
              alt="Omnidoc, plateforme de télé-expertise sécurisée et rémunérée"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
